@keyframes animateCallout {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-12px);
  }
  100% {
    transform: translateX(0);
  }
}

.navbarCallout {
  animation: animateCallout 1s infinite;
}
