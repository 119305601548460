@import url(https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,400;0,600;0,700;1,400;1,600;1,700&display=swap);
@keyframes animateCallout{0%{transform:translateX(0)}50%{transform:translateX(-12px)}100%{transform:translateX(0)}}.navbarCallout{animation:animateCallout 1s infinite}

.game1-intro strong{display:inline;margin:0 !important}

.IR01Form .ms-Dropdown-titleIsPlaceHolder{background-color:rgba(255,0,0,0.033) !important}


body{margin:0;font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale}code{font-family:Monaco, Menlo, Consolas, 'Roboto Mono', monospace}

